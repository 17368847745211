import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { routes } from '@usurp-power/aqua-silver';
import classnames from "classnames";
import { ThemeContext } from '$lib/context';
import { ProfileDropdown } from '$components/common/account';
import { FeedbackButton } from '$components/common/feedback';
import storage from '$lib/storage';

import styles from './styles.scss';

const DEFAULT_LOGO_URL = 'https://storage.googleapis.com/public-assets.usurppower.com/theme/usurp.png';

// 20200601JP: This is embarassing... but it's easy and it works.
const getMenuSelectedKeys = ({ location }) => {
  const { pathname } = location;
  if (/dashboard/.test(pathname)) {
    return ['dashboard'];
  }
  if (/application/.test(pathname)) {
    return ['applications'];
  }
  if (/users/.test(pathname)) {
    return ['users'];
  }
  if (routes.contractor.projects.new === pathname) {
    return ['new'];
  }
  if (/projects/.test(pathname)) {
    return ['projects'];
  }
  if (/applications/.test(pathname)) {
    return ['applications'];
  }
  if (/leads/.test(pathname)) {
    return ['leads'];
  }
  if (/quote/.test(pathname)) {
    return ['quote'];
  }
  return null;
};

const getMenuItems = ({ currentUser }) => {
  console.log(currentUser);
  const currentUserType = currentUser ? currentUser.type : null;

  if (currentUserType === 'lender') {
    return [
      <Menu.Item key="applications"><Link to={routes.lender.applications.new}>Applications</Link></Menu.Item>,
      <Menu.Item key="quote"><Link to={routes.lender.quote}>Quote</Link></Menu.Item>
    ];
  }

  let menuItems = [
    <Menu.Item key="applyForFinancing"><Link to={routes.contractor.projects.new} data-aaa="applyForFinancingNav">Apply for Financing</Link></Menu.Item>,
    <Menu.Item key="bankAbility"><Link to={routes.applicant.apply} data-aaa="bankAbilityScoreNav">Bank-ability score</Link></Menu.Item>,
    <Menu.Item key="cashFlowCalculator"><Link to={routes.contractor.projects.project.valueCalculator.replace(':contractorUuid', currentUser.uuid)} data-aaa="cashFlowCalulatorNav">Cash Flow Calculator</Link></Menu.Item>,
  ];

  if (currentUserType === 'admin') {
    menuItems = menuItems.concat([
      <Menu.Item key="dashboard">
        <Link to={routes.admin.dashboard}>Admin dash</Link>
      </Menu.Item>,
      <Menu.Item key="applications">
        <Link to={routes.admin.applications}>Admin apps</Link>
      </Menu.Item>,
      <Menu.Item key="users">
        <Link to={routes.admin.users}>Admin users</Link>
      </Menu.Item>
    ]);
  }

  if (currentUserType === 'applicant') {
    menuItems.unshift(
      <Menu.Item key="dashboard"><Link to={routes.applicant.dashboard}>My dashboard</Link></Menu.Item>
    );
  } else if (currentUser) {
    menuItems.unshift(
      <Menu.Item key="projects"><Link to={routes.contractor.projects.overview}>My projects</Link></Menu.Item>
    );
  }

  console.log(menuItems);

  return menuItems;
};

class DefaultLayout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    currentUser: PropTypes.object,
    location: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
  };

  constructor(props) {
    super(props);

    const isImpersonated = !!storage.tempAuth.get();

    this.state = {
      isImpersonated,
    };
  }

  render() {
    const {
      children,
      currentUser,
      location,
    } = this.props;
    const { isImpersonated } = this.state;

    const menuSelectedKeys = getMenuSelectedKeys({ location });
    const menuItems = getMenuItems({ currentUser });

    return (
      <ThemeContext.Consumer>
        {(theme) => {
          const { slug, logoUrl } = theme;

          return (
            <div className={styles.layout} data-aaa={`theme--${slug}`}>
              <div className={styles.header}>
                <div className={styles.headerNav}>
                  <Link to="/">
                    <div className={styles.logo}>
                      <img className={styles.logoImage} src={logoUrl || DEFAULT_LOGO_URL} />
                    </div>
                  </Link>
                  <Menu
                    className={styles.menu}
                    theme="dark"
                    mode="horizontal"
                    selectedKeys={menuSelectedKeys}
                    style={{ lineHeight: '64px' }}
                  >
                    {menuItems}
                  </Menu>
                </div>
                <div className={styles.menuIcons}>
                  <Link to={routes.contractor.projects.new} data-aaa="newProjectNav">
                    <Button className={classnames(styles.createButton, 'ant-btn-set-height')} type="primary" size="small">
                      <i className={'fas fa-plus'} data-aaa="profileAvatar" />
                      New project
                    </Button>
                  </Link>
                  <ProfileDropdown
                    location={location}
                    user={currentUser}
                  />
                  <Dropdown
                    className={styles.responsiveMenu}
                    trigger={['click']}
                    overlay={(
                      <Menu
                        selectedKeys={menuSelectedKeys}
                        style={{ lineHeight: '64px' }}
                      >
                        {menuItems}
                      </Menu>
                    )}
                  >
                    <div data-aaa="responsiveMenuIcon" className={styles.menuIcon}><i className="fas fa-bars" /></div>
                  </Dropdown>
                </div>
              </div>
              <div className={styles.content}>
                {children}
              </div>
              {!isImpersonated && <FeedbackButton />}
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export default DefaultLayout;
