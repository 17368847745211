import _ from 'lodash';
import moment from 'moment';

const formatMoney = ({ amount }) => {
  const parsed = parseFloat(amount);

  if (Number.isNaN(parsed)) {
    return null;
  }

  return `$${parsed.toLocaleString()}`
};
const formatNumber = (num) => `${parseFloat(num).toLocaleString()}`;

export const applicationInputs = {
  applicantEmail: 'applicantEmail',
  propertyStreetAddress: 'address',
  city: 'city',
  state: 'state',
  postalCode: 'postalCode',
  propertyType: 'propertyType',
  propertyBuildingSize: ({ propertyBuildingSize }) => formatNumber(propertyBuildingSize),
  ownerOccupied: 'ownerOccupied',
  singleTenant: 'singleTenant',
  propertyDebt: ({ propertyDebt }) => formatNumber(propertyDebt),
  lender: 'lender',
  otherLender: 'lenderOther',
  currentProjectStage: 'projectStage',
  scopeOfWork: ({ scopeOfWork }) => {
    try {
      const json = JSON.parse(scopeOfWork);
      return json.join(', ');
    } catch (e) {
      return '';
    }
  },
  ask: 'ask',
  providesAppraisal: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return Boolean(docs.indexOf('Property appraisal') > -1);
    } catch (e) {
      return false;
    }
  },
  providesFinancialDocuments: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return Boolean(docs.indexOf('3 years of audited financials') > -1);
    } catch (e) {
      return false;
    }
  },
  numberOfDocuments: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return docs.length;
    } catch (e) {
      return 0;
    }
  },
  utmSource: 'utmSource',
  fullYearOfPositiveNOI: 'propertyPositiveOperatingIncome',
  propertyMarketValue: 'propertyMarketValue',
  applicationPctCompleted: 'applicationPercentComplete',
  wal: 'wal',
  urgencyOfFinancing: ({ utmSource }) => utmSource === 'contractor_email',
  numberOfConflicts: ({ dataTree: { conflicts } }) => {
    try {
      const result = JSON.parse(conflicts);
      return result.length;
    } catch (e) {
      return 0;
    }
  }
};
export const dataTreeInputs = {
  propertyStreetAddress: 'dataTree.address.line',
  city: 'dataTree.address.city',
  state: 'dataTree.address.stateCode',
  postalCode: 'dataTree.address.postalCode',
  propertyType: 'dataTree.propertyType',
  propertyBuildingSize: ({ dataTree: { sqft } }) => formatNumber(sqft),
  ownerOccupied: 'dataTree.ownerOccupied',
  singleTenant: 'dataTree.singleTenant',
  propertyDebt: 'dataTree.tvr.debt',
  lender: ({ dataTree: { lenders } }) => {
    try {
      const result = JSON.parse(lenders);
      return result[0];
    } catch (e) {
      return null;
    }
  },
  otherLender: ({ dataTree: { lenders } }) => {
    try {
      const result = JSON.parse(lenders);
      return result.slice(1).join(', ');
    } catch (e) {
      return null;
    }
  },
  propertyMarketValue: 'dataTree.marketValue',
  buyer: 'dataTree.tvr.buyer',
  ownershipYears: 'dataTree.ownershipYears',
  deedType: 'usurp.deedType',
  yearOfLastTaxDelinquency: 'dataTree.taxDelinquencyYear',
  taxDelinquencyLast5Years: ({ dataTree: { taxDelinquencyYear } }) => {
    if (!taxDelinquencyYear) {
      return null;
    }

    const year = parseInt(taxDelinquencyYear, 10);
    return new Date().getFullYear() - year <= 5;
  },
  multipleTitleOwners: 'usurp.multipleTitleOwners',
  multipleRealOwners: 'usurp.multipleRealOwners',
  multipleOwnerAddress: 'dataTree.multipleOwnerMailingAddresses',
  singleLender: 'dataTree.singleLender',
  activeInvoluntaryLiens: 'usurp.numberActiveInvoluntaryLiens',
  numberActiveInvoluntaryLiens: 'usurp.numberActiveInvoluntaryLiens',
  nonActiveInvoluntaryLiens: 'usurp.nonActiveInvoluntaryLiensSummary',
  multipleDataTreeRecords: ({ dataTree: { apns } }) => {
    try {
      const result = JSON.parse(apns);
      return Boolean(result.length > 1);
    } catch (e) {
      return false;
    }
  },
  propertyOwner: 'usurp.propertyOwnerName',
  ownerMailingAddress: 'usurp.ownerMailingAddress',
  buildDate: 'usurp.propertyStructureBuiltYear',
  unitCount: 'usurp.unitCount',
};

export const aggregatedInputs = {
  propertyStreetAddress: ({ property: { streetNumber, route } }) => `${streetNumber} ${route}`,
  city: 'property.locality',
  state: 'property.state',
  postalCode: 'property.postalCode',
  county: 'property.county',
  propertyType: 'usurp.propertyType',
  propertyTypeConsentScore: 'usurp.propertyTypeConsentScore',
  propertyBuildingSize: ({ usurp: { sqft } }) => formatNumber(sqft),
  ownerOccupied: 'usurp.ownerOccupied',
  singleTenant: 'usurp.singleTenant',
  propertyDebt: 'usurp.debt',
  lender: 'usurp.lender',
  otherLender: '',
  currentProjectStage: 'projectStage',
  scopeOfWork: ({ scopeOfWork }) => {
    try {
      const json = JSON.parse(scopeOfWork);
      return json.join(', ');
    } catch (e) {
      return '';
    }
  },
  ask: 'ask',
  providesAppraisal: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return Boolean(docs.indexOf('Property appraisal') > -1);
    } catch (e) {
      return false;
    }
  },
  providesFinancialDocuments: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return Boolean(docs.indexOf('3 years of audited financials') > -1);
    } catch (e) {
      return false;
    }
  },
  numberOfDocuments: ({ availableDocuments }) => {
    try {
      const docs = JSON.parse(availableDocuments);
      return docs.length;
    } catch (e) {
      return 0;
    }
  },
  utmSource: 'utmSource',
  fakePhoneNumber: 'hasRealPhoneNumber',
  fullYearOfPositiveNOI: 'propertyPositiveOperatingIncome',
  dataTreeMarketValue: ({ dataTree: { marketValue } }) => formatMoney({ amount: marketValue}),
  propertyMarketValue: 'usurp.marketValue',
  buyer: 'usurp.buyer',
  ownershipYears: 'usurp.ownershipYears',
  deedType: 'usurp.deedType',
  yearOfLastTaxDelinquency: 'dataTree.taxDelinquencyYear',

  propertyOwner: 'usurp.propertyOwnerName',
  ownerMailingAddress: 'usurp.ownerMailingAddress',
  buildDate: 'usurp.propertyStructureBuiltYear',
  unitCount: 'usurp.unitCount',
  activeInvoluntaryLiens: 'usurp.numberActiveInvoluntaryLiens',
  numberActiveInvoluntaryLiens: 'usurp.numberActiveInvoluntaryLiens',
  nonActiveInvoluntaryLiens: 'usurp.nonActiveInvoluntaryLiensSummary',
  multipleDataTreeRecords: ({ dataTree: { apns } }) => {
    try {
      const result = JSON.parse(apns);
      return Boolean(result.length > 1);
    } catch (e) {
      return false;
    }
  },
  propertyAddressMatches: 'dataTree.propertyAddressMatches',
  ownerAddressMatch: (data) => {
    const match = _.get(data, 'dataTree.ownerAddressMatch');
    return !match;
  },
  quantDataMatch: (data) => {
    const match = _.get(data, 'dataTree.quantDataMatch');
    return !match;
  },
  titleOwnerMatch: 'dataTree.titleOwnerMatch',
  multipleApns: ({ dataTree: { apns, estimatedParcels } }) => {
    if (estimatedParcels === '>1') {
      return true;
    }
    try {
      const result = JSON.parse(apns);
      return Boolean(result.length > 1);
    } catch (e) {
      return false;
    }
  },
  applicationPctCompleted: 'applicationPercentComplete',

  wal: 'usurp.wal',
  apnSituation: 'usurp.dataTreeSituation',
  multipleTitleOwners: 'usurp.multipleTitleOwners',
  multipleRealOwners: 'usurp.multipleRealOwners',
  multipleOwnerAddress: 'usurp.multipleOwnerMailingAddresses',
  singleLender: 'usurp.singleLender',
  propertyClass: 'usurp.propertyClass',
  capRate: 'usurp.capRate',
  altv: 'usurp.altv',
  ltv: 'usurp.ltv',
  // numberOfConflicts: ({ dataTree: { conflicts } }) => {
  //   try {
  //     const result = JSON.parse(conflicts);
  //     return result.length;
  //   } catch (e) {
  //     return 0;
  //   }
  // },
  urgencyOfFinancing: ({ utmSource }) => utmSource === 'contractor_email',
  recentTaxDelinquency: '',
  unetyEstimatedTenacyType: (usurp) => {
    const { ownerOccupied, singleTenant } = usurp;
    if (singleTenant) {
      if (ownerOccupied) {
        return 'Single Tenant Owner Occupier';
      }
      return 'Single Tenant Non-Owner Occupier ';
    }
    if (ownerOccupied) {
      return 'Multi Tenant w/ Owner Occupier';
    }
    return 'Multi Tenant Non-Owner Occupier';
  },
  apn: 'application.dataTree.apn',
  apns: ({ application }) => application.parcels.map((parcel) => parcel.apn).join(', '),
  taxAssessedValue: ({ usurp: { taxAssessedValue } }) => formatMoney({ amount: taxAssessedValue}),
  lastSaleDate: ({ dataTree }) => {
    const val = _.get(dataTree, 'tvr.lastSaleDate');
    if (!val) {
      return null;
    }
    return moment(val).format('YYYY-MM-DD');
  },
  recentDebtAmount: ({ dataTree: { tvr: { debt } } }) => formatMoney({ amount: debt}),
  recentSalesPrice: ({ application }) => {
    const dataTreeJson = _.get(application, 'parcels[0].dataTreeJsonTvr');
    try {
      const json = JSON.parse(dataTreeJson);
      const result = _.get(json, 'Reports[0].Data.LastMarketSaleInformation.SalePrice');
      return formatMoney({ amount: result });
    } catch (e) { /* NOP */ }
    return null;
  },
  recentBuyer: ({ application, usurp }) => {
    const dataTreeJson = _.get(application, 'parcels[0].dataTreeJsonTvr');
    try {
      const json = JSON.parse(dataTreeJson);
      const result = _.get(application, 'dataTree.tvr.buyer') || usurp.buyer || _.get(json, 'Reports[0].Data.LastMarketSaleInformation.BuyerName') || null;
      return result;
    } catch (e) { /* NOP */ }
    return null;
  },
  recentSeller: ({ application }) => {
    const dataTreeJson = _.get(application, 'parcels[0].dataTreeJsonTvr');
    try {
      const json = JSON.parse(dataTreeJson);
      const result = _.get(json, 'Reports[0].Data.LastMarketSaleInformation.SellerName') || null;
      return result;
    } catch (e) { /* NOP */ }
    return null;
  },
  openLienType1: ({ application }) => {
    const dataTreeJson = _.get(application, 'parcels[0].dataTreeJsonTvr');
    try {
      const json = JSON.parse(dataTreeJson);
      const result = _.get(json, 'Reports[0].Data.InvoluntaryLienInfo.InvoluntaryLiensItems[0].DocumentCategory');
      return result;
    } catch (e) { /* NOP */ }
    return null;
  },
  openLienType2: ({ application }) => {
    const dataTreeJson = _.get(application, 'parcels[0].dataTreeJsonTvr');
    try {
      const json = JSON.parse(dataTreeJson);
      const result = _.get(json, 'Reports[0].Data.InvoluntaryLienInfo.InvoluntaryLiensItems[1].DocumentCategory');
      return result;
    } catch (e) { /* NOP */ }
    return null;
  },
  recentFinancingDate: ({ application }) => {
    const dataTreeJson = _.get(application, 'parcels[0].dataTreeJsonTvr');
    try {
      const json = JSON.parse(dataTreeJson);
      const result = _.get(json, 'Reports[0].Data.Transactions[0].TxDate');
      return moment(result).format('YYYY-MM-DD');
    } catch (e) { /* NOP */ }
    return null;
  },
  recentFinancingType: ({ application }) => {
    const dataTreeJson = _.get(application, 'parcels[0].dataTreeJsonTvr');
    try {
      const json = JSON.parse(dataTreeJson);
      const result = _.get(json, 'Reports[0].Data.Transactions[0].FinanceType');
      return result;
    } catch (e) { /* NOP */ }
    return null;
  },
  taxAssessedYear: ({ application }) => {
    const dataTreeJson = _.get(application, 'parcels[0].dataTreeJsonPdr');
    try {
      const json = JSON.parse(dataTreeJson);
      const result = _.get(json, 'Reports[0].Data.TaxInformation.AssessedYear');
      return result;
    } catch (e) { /* NOP */ }
    return null;
  },
};

const isConflict = (key, { dataTree: { conflicts } }) => {
  try {
    const result = JSON.parse(conflicts);
    return result.indexOf(key) !== -1;
  } catch (e) {
    return false;
  }
};

export const conflictInputs = {
  propertyBuildingSize: isConflict.bind(null, 'sqft'),
  propertyDebt: isConflict.bind(null, 'debt'),
  propertyMarketValue: isConflict.bind(null, 'marketValue'),
  ownerOccupied: isConflict.bind(null, 'ownerOccupied'),
  propertyType: isConflict.bind(null, 'propertyType'),
  singleTenant: isConflict.bind(null, 'singleTenant'),

};

export const rowLabels = [{
  title: 'Location Details',
  fields: [{
    label: 'Property street address',
    key: 'propertyStreetAddress',
  }, {
    label: 'City',
    key: 'city',
  }, {
    label: 'State',
    key: 'state',
  }, {
    label: 'Zip code',
    key: 'postalCode',
  }, {
    label: 'County',
    key: 'county',
  }, {
    label: 'Primary APN',
    key: 'apn',
  }, {
    label: 'All APNs',
    key: 'apns',
  }],
}, {
  title: 'Property Specs',
  fields: [{
    label: 'Property type',
    key: 'propertyType',
  }, {
    label: 'Building size (sq.ft.)',
    key: 'propertyBuildingSize',
  }, {
    label: 'Renovation/built date',
    key: 'buildDate',
  }, {
    label: '# of Known tenant units',
    key: 'unitCount',
  }, {
    label: 'Unety estimated tenancy type',
    key: 'unetyEstimatedTenacyType',
  }],
}, {
  title: 'Property financials',
  fields: [{
    label: 'Cap rate',
    key: 'capRate',
  }, {
    label: 'ALTV: Ratio of estimated outstanding debt to the Unety estimated market value',
    key: 'altv',
  }, {
    label: 'Unety estimated market value',
    key: 'propertyMarketValue',
  }, {
    label: 'Year of tax assessment',
    key: 'taxAssessedYear',
  }, {
    label: 'Tax assessed value',
    key: 'taxAssessedValue',
  }, {
    label: 'Estimated market value from valuation provider',
    key: 'dataTreeMarketValue',
  }, {
    label: 'Most recent sales price',
    key: 'recentSalesPrice',
  }, {
    label: 'Date of most recent sale',
    key: 'lastSaleDate',
  }, {
    label: 'Most recent seller',
    key: 'recentSeller',
  }, {
    label: 'Most recent buyer',
    key: 'recentBuyer',
  }, {
    label: 'Estimated outstanding balance of existing debt on property',
    key: 'propertyDebt',
  }, {
    label: 'Lender name of most recent property financing',
    key: 'lender',
  }, {
    label: 'Most recent amount of financing issued',
    key: 'recentDebtAmount',
  }, {
    label: 'Date of most recent financing',
    key: 'recentFinancingDate',
  }, {
    label: 'Type of most recent financing',
    key: 'recentFinancingType',
  }, {
    label: 'Year of last tax deliquency',
    key: 'yearOfLastTaxDelinquency',
  }, {
    label: 'Tax deliquency in the last 5 years',
    key: 'taxDelinquencyLast5Years',
  }, {
    label: 'Number of Active Involuntary Liens',
    key: 'numberActiveInvoluntaryLiens',
  }, {
    label: 'Number of non-active involuntary liens',
    key: 'nonActiveInvoluntaryLiens',
  }, {
    label: 'Open lien type 1',
    key: 'openLienType1',
  }, {
    label: 'Open lien type 2',
    key: 'openLienType2',
  }],
}, {
  title: 'Ownership details',
  fields: [{
    label: 'Deed type',
    key: 'deedType',
  }, {
    label: 'Legal name of property owner entity',
    key: 'propertyOwner',
  }, {
    label: 'Owner mailing address (street address)',
    key: 'ownerMailingAddress',
  }, {
    label: 'Years held by current owner(s)',
    key: 'ownershipYears',
  }, {
    label: 'Owner Names Constant Across Records',
    key: 'multipleRealOwners',
  }],
}, {
  title: 'Other Property Insights',
  fields: [{
    label: 'In lieu of title insurance, some challenges adversely effecting the ease of pulling together paperwork needed to close financing on this property include',
    key: 'apnSituation',
  }, {
    label: 'Searched address matches property records',
    key: 'propertyAddressMatches',
  }, {
    label: 'All property records associated with one property owner',
    key: 'ownerAddressMatch',
  }, {
    label: 'Property data fragmented across multiple records',
    key: 'quantDataMatch',
  }, {
    label: 'All property records associated with one property holding company',
    key: 'titleOwnerMatch',
  }],
}];

const APN_SITUATIONS = {
  1: 'Associated with this property are disparate property datasets and potentially multiple holding companies.',
  2: 'Associated with this property are disparate property datasets and some uncertainity over the accuracy of the official property address.',
  3: 'Associated with this property are potentially multiple holding companies and real owners.',
  4: 'Associated with this property are potentially multiple real owners and some uncertainity over the accuracy of the official property address.',
  5: 'Associated with this property are disparate property datasets.',
  6: 'Associated with this property are disparate property datasets and potentially multiple holding companies or real owners.',
  7: 'Associated with this property are disparate property datasets, potentially multiple holding companies, and some uncertainity over the accuracy of the official property address.',
  8: 'Associated with this property are some uncertainity over the accuracy of the official property address and potentially multiple holding companies or real owners.',
  9: 'There are few red flags related to ownership composition or associated datasets.',
  10: 'Associated with this property are disparate property datasets, potentially multiple holding companies or real owners, and some uncertainity over the accuracy of the official property address.',
  11: 'Associated with this property are disparate property datasets and potentially multiple real owners.',
  12: 'Associated with this property are disparate property datasets, potentially multiple real owners, and some uncertainity over the accuracy of the official property address .',
  13: 'Associated with this property are potentially multiple holding companies.',
  14: 'Associated with this property are potentially multiple holding companies and some uncertainity over the accuracy of the official property address.',
  15: 'Associated with this property is some uncertainity over the accuracy of the official property address.',
  16: 'Associated with this property are potentially multiple multiple real owners.',
  17: 'Associated with this property is some uncertainity over the accuracy of the official property address.',
  18: 'There are few red flags related to ownership composition or associated datasets.',
};

export const getTextForApnSituation = (apnSituation) => APN_SITUATIONS[apnSituation];
