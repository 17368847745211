/* eslint-disable react/display-name */
import React from 'react';
import RiskIcon from '$components/RiskIcon';
import styles from './styles.scss';

export const scoresData = [
  {
    className: styles.layout__headline,
    label: 'Overall Bank-ability Score',
    key: 'usurp.usurpScore',
    rating: ({ usurp }) => () => <span>{usurp.usurpRating}</span>,
  },
  {
    className: styles.layout__subHeadline,
    label: 'Usurp Score - Documentation',
    key: 'usurp.documentationScore',
    rating: ({ usurp }) => () => <span>{usurp.documentationRating} / 3</span>,
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Available documents',
    key: 'usurp.availableDocumentsScore',
    show: ({ isContractorApplication }) => !isContractorApplication
  },
  {
    label: 'Conflict score',
    key: 'usurp.applicationConflictsScore',
    show: ({ isContractorApplication }) => !isContractorApplication
  },
  {
    className: styles.layout__subHeadline,
    label: 'Unety credit score',
    key: 'usurp.creditScore',
    rating: ({ usurp }) => () => <span>{usurp.creditRating} / 3</span>,
  },
  {
    label: 'Unety cap rate score',
    key: 'usurp.capRateScore'
  },
  {
    label: 'Unety property type score',
    key: 'usurp.propertyTypeConsentScore'
  },
  {
    label: 'ALTV score',
    key: 'usurp.altvScore'
  },
  {
    label: 'Tenancy score',
    key: 'usurp.multiTenantScore'
  },
  {
    label: 'Property class score',
    key: 'usurp.propertyClassScore'
  },
  {
    label: 'Ownership duration score',
    key: 'usurp.ownershipDurationScore'
  },
  {
    label: 'Property class',
    key: 'usurp.propertyClass'
  },
  {
    label: 'NOI score',
    key: 'usurp.noiScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    className: styles.layout__subHeadline,
    label: 'Title score',
    key: 'usurp.titleScore',
    rating: ({ usurp }) => () => <RiskIcon value={usurp.titleRiskRating} />,
  },
  {
    className: styles.layout__subHeadline,
    label: 'Existing lender consent score',
    key: 'usurp.lenderConsentScore',
    rating: ({ usurp }) => () => <RiskIcon value={usurp.lenderConsentRiskRating} />,
  },
  {
    label: 'Property type consent score',
    key: 'usurp.propertyTypeConsentScore'
  },
  {
    label: 'Lender score',
    key: 'usurp.lenderConsentScore'
  },
  {
    className: styles.layout__subHeadline,
    label: 'Environmental risk score',
    key: 'usurp.environmentalScore',
    rating: ({ usurp }) => () => <RiskIcon value={usurp.environmentalRiskRating} />,
  },
];

export const communicationScoreDate = [
  {
    className: styles.layout__subHeadline,
    label: 'Communication Score',
    key: 'usurp.communicationScore',
    rating: ({ usurp }) => () => <span>{usurp.communicationRating}</span>,
    show: ({ isContractorApplication }) => !isContractorApplication
  },
  {
    label: 'Multiple real owner score',
    key: 'usurp.multipleRealOwnersScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Phone number score',
    key: 'usurp.phoneNumberScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Project stage score',
    key: 'usurp.projectStageScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Website time score',
    key: 'usurp.websiteTimeScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Application completed score',
    key: 'usurp.applicationCompletenessScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Application source score',
    key: 'usurp.applicationSourceScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
  {
    label: 'Financial urgency score',
    key: 'usurp.financingUrgencyScore',
    show: ({ isContractorApplication }) => !isContractorApplication,
  },
];
