import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Icon, Tooltip } from 'antd';
import styles from './breakdown.scss';
import { formatNumber } from '../../../lib/formatter';

class FundingOptionsBreakdown extends PureComponent {
  static propTypes = {

    newRevenueFromTenantsPace: PropTypes.number,
    newRevenueFromTenantsLoan: PropTypes.number,
    newRevenueFromTenantsCash: PropTypes.number,

    owedFromLandlordPace: PropTypes.number,
    owedFromLandlordLoan: PropTypes.number,
    owedFromLandlordCash: PropTypes.number,


    tenantCostIncreasePace: PropTypes.number,
    tenantCostIncreaseLoan: PropTypes.number,
    tenantCostIncreaseCash: PropTypes.number,
  };

  static defaultProps = {
    newRevenueFromTenantsPace: 0,
    newRevenueFromTenantsLoan: 0,
    newRevenueFromTenantsCash: 0,

    owedFromLandlordPace: 0,
    owedFromLandlordLoan: 0,
    owedFromLandlordCash: 0,


    tenantCostIncreasePace: 0,
    tenantCostIncreaseLoan: 0,
    tenantCostIncreaseCash: 0,
  };

  render() {
    const {
      contractorRate,
      interestRate,
      newRevenueFromTenantsPace,
      newRevenueFromTenantsLoan,
      newRevenueFromTenantsCash,
      owedFromLandlordPace,
      owedFromLandlordLoan,
      owedFromLandlordCash,
      tenantCostIncreasePace,
      tenantCostIncreaseLoan,
      tenantCostIncreaseCash,
    } = this.props;

    return (
      <div className={[styles.breakdown, 'breakdown'].join(' ')}>
        <div className={styles.propertyMetrics}>
          <div className={styles.propertyMetrics__blockContainer}>
            <div className={styles.propertyMetrics__tooltip}>
              <Tooltip placement="top" title={<span>This is the total annual payments paid by the property owner, net tenant reimbursables. <br/> If traditional debt financing with an interest rate of {Math.min(1.5, Math.max(0, contractorRate - 2))}% was used instead of PACE/OnBill, then year 1 cost to the owner would be [insert output of existing loan formula]</span>}>
                <Icon type="info-circle" />
              </Tooltip>
            </div>
            <div className={styles.propertyMetrics__block}>
              <div className={styles.propertyMetrics__label}>
                Year 1 cost to owner
              </div>
              <div className={styles.propertyMetrics__price}>
                ${formatNumber(owedFromLandlordPace)}
              </div>
            </div>
          </div>
          <div className={styles.propertyMetrics__blockContainer}>
            <div className={styles.propertyMetrics__tooltip}>
              <Tooltip placement="top" title="This is how much your tenants will pay in new property taxes">
                <Icon type="info-circle" />
              </Tooltip>
            </div>
            <div className={styles.propertyMetrics__block}>
              <div className={styles.propertyMetrics__label}>
                Cost to tenant
              </div>
              <div className={styles.propertyMetrics__price}>
                ${(tenantCostIncreasePace || 0).toFixed(2)} <sup>per SF/mo</sup>
              </div>
            </div>
          </div>
          <div className={styles.propertyMetrics__blockContainer}>
            <div className={styles.propertyMetrics__tooltip}>
              <Tooltip placement="top" title="This is how much your revenue will increase as a result of new tenant tax recovery revenue">
                <Icon type="info-circle" />
              </Tooltip>
            </div>
            <div className={styles.propertyMetrics__block}>
              <div className={styles.propertyMetrics__label}>
                New revenue
              </div>
              <div className={styles.propertyMetrics__price}>
                ${formatNumber(Math.round(newRevenueFromTenantsPace || 0), parseFloat)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FundingOptionsBreakdown;
